<template>
  <div>
    <v-dialog width="500" v-model="dialog.flag">
      <v-card>
        <v-card-title
          class="primary_2 white--text py-3 d-flex justify-space-between"
        >
          <div>Edit Eligibility Check Details</div>
          <v-btn fab text small @click="dialog.flag = false">
            <v-icon color="white" large>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="6" class="pb-0 pt-6">
                <div>First Name</div>
                <v-text-field
                  v-model="dialog.first_name"
                  outlined
                  dense
                  :rules="$rules.NAME"
                  placeholder="Enter First Name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pb-0 pt-6">
                <div>Last Name</div>
                <v-text-field
                  v-model="dialog.last_name"
                  outlined
                  dense
                  placeholder="Enter Last Name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
                <div>Date of Birth</div>
                <v-menu
                  v-model="dobPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dialog.dob"
                      placeholder="EMI Start Date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      required
                      :rules="$rules.REQUIRED_FIELD"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      background-color="lightInput"
                      class="stepper-content-data"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dialog.dob"
                    @input="dobPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="py-0">
                <div>Father's Name</div>
                <v-text-field
                  v-model="dialog.father_name"
                  outlined
                  dense
                  :rules="$rules.REQURIED_FILED"
                  placeholder="Enter Father's Name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valid"
            color="secondary_2"
            :disable="btn_loader"
            :loading="btn_loader"
            class="px-4 mr-3 mb-2 font-weight-bold white--text"
            @click="updateEC()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["dialog"],
  data() {
    return {
      valid: true,
      dobPicker: false,
      btn_loader: false,
    };
  },
  methods: {
    updateEC() {
      if (this.$refs.form.validate()) {
        const self = this;

        self.btn_loader = true;

        let params = this.decrypt(this.$route.params.id);
        let body = {
          first_name: this.dialog.first_name,
          last_name: this.dialog.last_name,
          date_of_birth: this.dialog.dob,
          father_name: this.dialog.father_name,
        };

        const successHandler = (response) => {
          if (response.data.success) {
            self.dialog.flag = false;
            this.$emit("success");
          }
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        self.request_PATCH(
          self,
          self.$urls.NEW_CUSTOMER_UPDATE_ELIGIBILITY_CHECK,
          params,
          body,
          successHandler,
          null,
          null,
          finallyHandler
        );
      }
    },
  },
};
</script>
